import { createContext, ReactNode, useContext } from 'react';
import Big from 'big.js';
import { State, useHookstate } from '@hookstate/core';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { schema_latest_version } from '~/lib/zod';
import { TenantPackageConfig } from '~/tenants/common/registry';
import { v4 } from 'uuid';
import z from 'zod';
import { useTenant } from '~/tenants/common/TenantContextProvider';
import ZodForm, { useZodFormFieldArray, ZodNestedForm } from '~/components/zod/ZodForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';

interface PackageFormState {
  revenue: Big;
  savings: Big;
}

interface Context<O extends TenantOrderContext = TenantOrderContext> {
  state: State<PackageFormState>;
  orderContext: TenantOrderContext;
  package: TenantPackageConfig;
}

const PackageFormContext = createContext<Context | null>(null);

export function usePackageFormContext<O extends TenantOrderContext = TenantOrderContext>() {
  const context = useContext<Context<O>>(PackageFormContext as any);

  if (!context) {
    throw new Error('Must be used within a PackageForm');
  }

  return {
    package: context.package,
    orderContext: context.orderContext,
    state: useHookstate(context.state),
  };
}

export function PackageFormSavings() {
  const { state } = usePackageFormContext();
  const revenue = state.savings.get();

  if (!revenue || revenue.eq(0)) {
    return <></>;
  }

  return <p className="text-xs text-gray-600">You are saving ${revenue.times(-1).toFixed(2)}!</p>;
}

export function PackageFormRevenue() {
  const { state } = usePackageFormContext();
  const revenue = state.revenue.get();

  if (revenue) {
    return <>${revenue.toFixed(2)}</>;
  } else {
    return <>$0.00</>;
  }
}

export function PackageFormNested(props: { children: ReactNode; performable: TenantPerformableConfig }) {
  const context = usePackageFormContext();
  const [performables] = useZodFormFieldArray('performables');

  for (let index = 0; index < performables.length; index += 1) {
    const config = context.package.performables[index];

    if (props.performable !== config) {
      continue;
    }

    return <ZodNestedForm name={performables[index].name}>{props.children}</ZodNestedForm>;
  }

  throw new Error(`Could not find performable ${props.performable.id} in package ${context.package.id}`);
}

export function PackageFormHidden() {
  const context = usePackageFormContext();

  const [performables] = useZodFormFieldArray('performables');

  return (
    <>
      {performables.map(({ name }, index) => {
        const config = context.package.performables[index];

        return (
          <ZodNestedForm key={config.id} name={name}>
            <ZodFieldHidden name="version" value={schema_latest_version(config.schema)} />
          </ZodNestedForm>
        );
      })}
    </>
  );
}

export default function PackageForm(props: {
  package: TenantPackageConfig;
  children: ReactNode;
  orderContext: TenantOrderContext;
}) {
  const tenant = useTenant();
  const [first, ...remaining] = props.package.performables.map((performable) => {
    const version = schema_latest_version(performable.schema);

    return performable.schema[version];
  });

  const schema = z.object({
    performables: z.tuple([first, ...remaining]),
  });

  const revenue = props.package.performables.reduce((acc, p) => {
    const job = new tenant.performables[p.id].job(props.orderContext);

    return acc.plus(job.revenue());
  }, new Big(0));

  const savings = revenue.times(props.package.percentage ?? 0);

  const state = useHookstate<PackageFormState>({ revenue: revenue.minus(savings), savings });

  return (
    <PackageFormContext.Provider value={{ package: props.package, state, orderContext: props.orderContext }}>
      <ZodForm
        schema={schema}
        defaultValues={{
          performables: props.package.performables.map((p) => {
            const job = new tenant.performables[p.id].job(props.orderContext);

            return job.defaultValue();
          }),
        }}
        onValid={(data) => {
          const jobs = props.package.performables.map((performable, index) => ({
            id: v4(),
            performable_id: performable.id,
            metadata: data.performables[index],
          }));

          const ids = jobs.map((job) => job.id);

          const context: TenantOrderContext = {
            ...props.orderContext,
            jobs: [
              ...props.orderContext.jobs,
              ...jobs,
            ],
          };

          const order = new tenant.orderClass(tenant, context);
          const lines = order
            .revenueLines()
            .filter((line) => line.discount || (line.job_id && ids.includes(line.job_id)));
          const revenue = lines.reduce((sum, line) => sum.plus(line.amount), Big(0));
          const savings = order.revenueLines().find((line) => line.discount)?.amount || Big(0);

          state.merge({
            revenue,
            savings,
          });
        }}
      >
        {props.children}
      </ZodForm>
    </PackageFormContext.Provider>
  );
}
